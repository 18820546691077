import $ from "jquery";
import Swiper from "swiper";
import enquire from "enquire.js";
import { carouselConfig as config } from "../app-config";
import { MQ_MOBILE_SMALL, MQ_MOBILE } from "../constants";

const carouselConfig = {
  base: {
    direction: "horizontal",
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next"
    },
    on: {
      init() {
        // Active slides: active-slide + the flanking slides (maxDistance = 1)
        updateAttributes(this, false, 1);
      },
      slideChange() {
        updateAttributes(this, false, 1);
      }
    }
  },
  vertical: {
    slidesPerView: "auto",
    slidesOffsetBefore: 30,
    breakpoints: {
      320: {
        slidesOffsetBefore: 20,
        spaceBetween: 20
      },
      610: {
        slidesOffsetBefore: 0,
        spaceBetween: 30,
        centeredSlides: true
      }
    },
    on: {
      init() {
        // Active slides: active-slide + the 3 slides next to it to the right (maxDistance = 3)
        updateAttributes(this, true, 3);
      },
      slideChange() {
        updateAttributes(this, true, 3);
      }
    }
  },
  horizontal: {
    slidesPerView: "auto",
    slidesOffsetBefore: 30,
    breakpoints: {
      320: {
        slidesOffsetBefore: 0,
        spaceBetween: 10,
        centeredSlides: true
      }
    }
  },
  hero: {
    // slidesOffsetBefore: 60,
    // spaceBetween: 80,
    autoplay: {
      delay: config.autoPlayDelay || 3000
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    },
    slidesOffsetBefore: 50,
    spaceBetween: 50,
    breakpoints: {
      991: {
        slidesOffsetBefore: 25,
        spaceBetween: 25
      }
    }
  },
  education: {
    centeredSlides: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets"
    },
    slidesOffsetBefore: 50,
    spaceBetween: 50,
    breakpoints: {
      991: {
        spaceBetween: 25,
        slidesOffsetBefore: 25
      }
    }
  },
  whatsNew: {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets"
    }
  }
};

/**
 * Updates attributes of slides based on the provided criteria.
 * @param {Object} carousel - The slider component instance.
 * @param {boolean} isVertical - A boolean indicating whether the carousel is using vertical config.
 * @param {number} maxDistance - The maximum distance allowed for slides to be considered active.
 */
function updateAttributes(carousel, isVertical, maxDistance) {
  const slides = Array.from(carousel.slides);

  // Checks the distance of the current slide from the active slide
  const condition = distance => distance >= 0 && distance <= maxDistance;

  slides.forEach((slide, i) => {
    const distance = isVertical
      ? i - carousel.activeIndex
      : Math.abs(i - carousel.activeIndex);

    if (condition(distance)) {
      slide.setAttribute('aria-hidden', 'false');
      slide.removeAttribute('inert');
    } else {
      slide.setAttribute('aria-hidden', 'true');
      slide.inert = true;
    }
  });
}

/**
 * Returns the appropriate config based on classes on the carousel
 * container elemenent.
 * @param {HTMLElement} container The PFS carousel component container
 * @returns {Object} The carousel config object.
 */
function getCarouselConfig(container) {
  const base = Object.assign({}, carouselConfig.base);
  const { classList } = container;
  if (classList.contains("pfs-carousel-vcard")) {
    return Object.assign(base, carouselConfig.vertical, {
      pfsCarouselType: "vcard"
    });
  }
  if (classList.contains("pfs-carousel-hcard")) {
    return Object.assign(base, carouselConfig.horizontal, {
      pfsCarouselType: "hcard"
    });
  }
  if (classList.contains("pfs-hero-carousel")) {
    return Object.assign(base, carouselConfig.hero, {
      pfsCarouselType: "hero"
    });
  }
  if (classList.contains("pfs-education-carousel")) {
    return Object.assign(base, carouselConfig.education, {
      pfsCarouselType: "education"
    });
  }
  if (classList.contains("pfs-whats-new-carousel")) {
    return Object.assign(base, carouselConfig.whatsNew, {
      pfsCarouselType: "whats-new"
    });
  }
  return base;
}

/**
 * Creates a new Swiper instance.
 * @param {HTMLElement} container The PFS carousel component container
 * @param {Object} config The swiper.js configuration object.
 * @returns {Object} The new Swiper instance.
 */
function buildCarousel(container, config) {
  // eslint-disable-next-line no-new
  const swiperInstance = new Swiper(container.querySelector(".swiper-container"), config);

  // Keyboard users can tab through carousels.
  const arrowPrev = container.querySelector('.swiper-button-prev')
  if(arrowPrev) {
    arrowPrev.setAttribute('aria-hidden', 'true');
    arrowPrev.setAttribute('tabindex', '-1');
  }
  const arrowNext = container.querySelector('.swiper-button-next')
  if(arrowNext) {
    arrowNext.setAttribute('aria-hidden', 'true');
    arrowNext.setAttribute('tabindex', '-1');
  }

  return swiperInstance;
}

const carousel = {
  init() {
    const carousels = $(".pfs-carousel")
      .toArray()
      .map(container => buildCarousel(container, getCarouselConfig(container)));

    $(".pfs-branded-carousel")
      .toArray()
      .forEach(container => {
        const $container = $(container);
        const $heading = $container.find(".heading-container");
        const $carousel = $container.find(".swiper-container");
        enquire.register(MQ_MOBILE_SMALL, {
          match() {
            $heading.insertAfter($carousel);
          },
          unmatch() {
            $heading.insertBefore($carousel);
          }
        });
      });

    carousels
      .filter(c => c.params.pfsCarouselType === "hero")
      .forEach(c => {
        enquire.register(MQ_MOBILE, {
          match() {
            c.autoplay.stop();
          },
          unmatch() {
            c.autoplay.start();
          }
        });
      });
  }
};

export default carousel;
