import "@babel/polyfill";
import "bootstrap";
import {
  closest,
  includes,
  remove
} from "./polyfills";
import {
  fullPageScroll,
  anchorTags,
  layoutUtils,
  imageManager,
  keyboardManager,
  superscript
} from "./utils";
import {
  header,
  heroCarousel,
  productGrid,
  carousel,
  readMore,
  videoTutorial,
  featuredArticleList,
  search,
  brandFilter,
  formFields,
  locationSelector,
  scrollToTop,
  formFieldsIsRequired
} from "./components";

function init() {
  closest.init();
  includes.init();
  remove.init();
  fullPageScroll.init();
  anchorTags.init();
  header.init();
  heroCarousel.init();
  productGrid.init();
  carousel.init();
  readMore.init();
  videoTutorial.init();
  featuredArticleList.init();
  search.init();
  layoutUtils.init();
  brandFilter.init();
  formFields.init();
  imageManager.init();
  keyboardManager.init();
  locationSelector.init();
  scrollToTop.init();
  formFieldsIsRequired.init();
  superscript.init();
}

window.addEventListener("DOMContentLoaded", init);
